import styled from "styled-components"

const LayoutStyles = styled("div")`
  z-index: 10;
  /* display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
  .bar {
    width: 80vw;
    .bar-text {
      background-color: black;
      color: white;
      padding: 5px 8px 5px 8px;
      @media (max-width: 700px) {
        margin: 0 10px 0 10px;
      }
    }
    hr {
      background-color: black;
      height: 10px;
      @media (max-width: 700px) {
        margin: 0 10px 0 10px;
      }
    }
  } */
`

export default LayoutStyles
