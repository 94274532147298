import React from 'react';
import FooterStyles from '../styles/footerStyles'
const Footer = () => {
  return (
    <FooterStyles>
      <footer>
        <p>© 2020 THE JACKSON CRITIC</p>
      </footer>
    </FooterStyles>
  );
};

export default Footer;