import { Link } from "gatsby"
import PropTypes from "prop-types"
import React from "react"
import HeaderStyles from "../styles/headerStyles"
import { useStaticQuery, graphql } from "gatsby"
import Img from "gatsby-image"
// import Image from './image'
const Header = ({ siteTitle }) => {
  const imgdata = useStaticQuery(graphql`
    query headerQuery {
      logo: file(relativePath: { eq: "logo-dark.png" }) {
        childImageSharp {
          fluid {
            ...GatsbyImageSharpFluid
          }
        }
      }
    }
  `)

  return (
    <HeaderStyles>
      <div className='logo'>
        <Link to="/">
          <Img
            fluid={imgdata.logo.childImageSharp.fluid}
            alt="jackson critic logo"
          />
        </Link>
      </div>
      <hr className='header-hr'/>
      <div className="nav">
        <Link to="/">home</Link>
        <Link to="/reviews">Food</Link>
        <Link to="/podcast">podcast</Link>
        <Link to="/subscribe">subscribe</Link>
      </div>
    </HeaderStyles>
  )
}

Header.propTypes = {
  siteTitle: PropTypes.string,
}

Header.defaultProps = {
  siteTitle: `the jackson critic`,
}

export default Header
