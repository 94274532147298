import styled from "styled-components"

const HeaderStyles = styled("header")`
  margin: 0 auto;
  .logo {
    width: 300px;
    margin: 0 auto;
  }
  .header-hr {
    width: 90vw;
    height: 10px;
    margin: 0 auto;
    background-color: #2b1d0e;
  }
  .nav {
    display: flex;
    justify-content: space-around;
    a {
      text-decoration: none;
      color: #2b1d0e;
      padding: 10px;
      text-transform: uppercase;
    }
  }
`

export default HeaderStyles
