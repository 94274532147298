import styled from "styled-components"

const FooterStyles = styled("div")`
  height: 200px;
  bottom: 0;
  width: 100vw;
  background-color: #2b1d0e;
  color: white;
  text-align: center;
  p {
    padding-top: 20px;
  }
`

export default FooterStyles
